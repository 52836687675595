export const environment = {
  production: true,  
  mapsKey: 'AIzaSyDTzinomP2r7CYWAL8V2STW0BXnXi_RV_4',
  firebase: {
    apiKey: "AIzaSyAY30Q0y0pcRG3aKOpndjT1ftUeHHI692Q",
  authDomain: "push-notifications-219b1.firebaseapp.com",
  databaseURL: "https://push-notifications-219b1.firebaseio.com",
  projectId: "push-notifications-219b1",
  storageBucket: "push-notifications-219b1.appspot.com",
  messagingSenderId: "814471264227",
  appId: "1:814471264227:web:71cdb28c8f71af5af952d6",
  measurementId: "G-2Z138WMF2K",
    vapidKey: "BFhVz-xsXTDFosmzxSGVgkJSIB5LN29rb5h8rzwUdLy6Mlju26dROZnKNPOWngI8kof_WlS92WKHczyr4-3zd2w"
  },
};
