import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import * as i0 from "@angular/core";
var Calendar = /** @class */function (_super) {
  __extends(Calendar, _super);
  function Calendar() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Calendar.prototype.hasReadWritePermission = function () {
    return cordova(this, "hasReadWritePermission", {}, arguments);
  };
  Calendar.prototype.hasReadPermission = function () {
    return cordova(this, "hasReadPermission", {}, arguments);
  };
  Calendar.prototype.hasWritePermission = function () {
    return cordova(this, "hasWritePermission", {}, arguments);
  };
  Calendar.prototype.requestWritePermission = function () {
    return cordova(this, "requestWritePermission", {}, arguments);
  };
  Calendar.prototype.requestReadPermission = function () {
    return cordova(this, "requestReadPermission", {}, arguments);
  };
  Calendar.prototype.requestReadWritePermission = function () {
    return cordova(this, "requestReadWritePermission", {}, arguments);
  };
  Calendar.prototype.createCalendar = function (nameOrOptions) {
    return cordova(this, "createCalendar", {}, arguments);
  };
  Calendar.prototype.deleteCalendar = function (name) {
    return cordova(this, "deleteCalendar", {}, arguments);
  };
  Calendar.prototype.getCalendarOptions = function () {
    return cordova(this, "getCalendarOptions", {
      "sync": true
    }, arguments);
  };
  Calendar.prototype.getCreateCalendarOptions = function () {
    return cordova(this, "getCreateCalendarOptions", {
      "sync": true
    }, arguments);
  };
  Calendar.prototype.createEvent = function (title, location, notes, startDate, endDate) {
    return cordova(this, "createEvent", {}, arguments);
  };
  Calendar.prototype.createEventWithOptions = function (title, location, notes, startDate, endDate, options) {
    return cordova(this, "createEventWithOptions", {}, arguments);
  };
  Calendar.prototype.createEventInteractively = function (title, location, notes, startDate, endDate) {
    return cordova(this, "createEventInteractively", {}, arguments);
  };
  Calendar.prototype.createEventInteractivelyWithOptions = function (title, location, notes, startDate, endDate, options) {
    return cordova(this, "createEventInteractivelyWithOptions", {}, arguments);
  };
  Calendar.prototype.findEvent = function (title, location, notes, startDate, endDate) {
    return cordova(this, "findEvent", {}, arguments);
  };
  Calendar.prototype.findEventWithOptions = function (title, location, notes, startDate, endDate, options) {
    return cordova(this, "findEventWithOptions", {}, arguments);
  };
  Calendar.prototype.listEventsInRange = function (startDate, endDate) {
    return cordova(this, "listEventsInRange", {
      "platforms": ["Android"]
    }, arguments);
  };
  Calendar.prototype.listCalendars = function () {
    return cordova(this, "listCalendars", {}, arguments);
  };
  Calendar.prototype.findAllEventsInNamedCalendar = function (calendarName) {
    return cordova(this, "findAllEventsInNamedCalendar", {
      "platforms": ["iOS"]
    }, arguments);
  };
  Calendar.prototype.modifyEvent = function (title, location, notes, startDate, endDate, newTitle, newLocation, newNotes, newStartDate, newEndDate) {
    return cordova(this, "modifyEvent", {
      "platforms": ["iOS"]
    }, arguments);
  };
  Calendar.prototype.modifyEventWithOptions = function (title, location, notes, startDate, endDate, newTitle, newLocation, newNotes, newStartDate, newEndDate, filterOptions, newOptions) {
    return cordova(this, "modifyEventWithOptions", {
      "platforms": ["iOS"]
    }, arguments);
  };
  Calendar.prototype.deleteEvent = function (title, location, notes, startDate, endDate) {
    return cordova(this, "deleteEvent", {}, arguments);
  };
  Calendar.prototype.deleteEventFromNamedCalendar = function (title, location, notes, startDate, endDate, calendarName) {
    return cordova(this, "deleteEventFromNamedCalendar", {
      "platforms": ["iOS"]
    }, arguments);
  };
  Calendar.prototype.deleteEventById = function (id, fromDate) {
    return cordova(this, "deleteEventById", {}, arguments);
  };
  Calendar.prototype.openCalendar = function (date) {
    return cordova(this, "openCalendar", {}, arguments);
  };
  Calendar.ɵfac = /* @__PURE__ */(() => {
    let ɵCalendar_BaseFactory;
    return function Calendar_Factory(__ngFactoryType__) {
      return (ɵCalendar_BaseFactory || (ɵCalendar_BaseFactory = i0.ɵɵgetInheritedFactory(Calendar)))(__ngFactoryType__ || Calendar);
    };
  })();
  Calendar.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: Calendar,
    factory: Calendar.ɵfac
  });
  Calendar.pluginName = "Calendar";
  Calendar.plugin = "cordova-plugin-calendar";
  Calendar.pluginRef = "plugins.calendar";
  Calendar.repo = "https://github.com/EddyVerbruggen/Calendar-PhoneGap-Plugin";
  Calendar.platforms = ["Android", "iOS"];
  Calendar = __decorate([], Calendar);
  return Calendar;
}(AwesomeCordovaNativePlugin);
export { Calendar };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && void 0;
})();
